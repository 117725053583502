import { combineReducers } from "redux-immutable"
import { RESET_STATE } from "../actions/actionTypes"
import decision from "./decision"
import summaryOutcomes from "./summaryOutcomes"
import outcomeValues from "./outcomeValues"
import riskCalculator from "./riskCalculator"

const applicationReducer = combineReducers({
  decision,
  summaryOutcomes,
  outcomeValues,
  riskCalculator,
})

const rootReducer = (state, action) => {
  if (action.type === RESET_STATE) {
    state = undefined
  }
  return applicationReducer(state, action)
}

export default rootReducer
