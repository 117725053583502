// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-start-page-template-js": () => import("../src/templates/start-page-template.js" /* webpackChunkName: "component---src-templates-start-page-template-js" */),
  "component---src-templates-summary-template-js": () => import("../src/templates/summary-template.js" /* webpackChunkName: "component---src-templates-summary-template-js" */),
  "component---src-templates-risk-template-js": () => import("../src/templates/risk-template.js" /* webpackChunkName: "component---src-templates-risk-template-js" */),
  "component---src-templates-facts-template-js": () => import("../src/templates/facts-template.js" /* webpackChunkName: "component---src-templates-facts-template-js" */),
  "component---src-templates-options-template-js": () => import("../src/templates/options-template.js" /* webpackChunkName: "component---src-templates-options-template-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

