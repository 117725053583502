import Immutable from "immutable"
import { SET_OUTCOME_VALUE } from "../actions/actionTypes"

const initialState = Immutable.Map()

const outcomeValues = (state = initialState, action) => {
  switch (action.type) {
    case SET_OUTCOME_VALUE:
      return state.set(action.outcomeId, action.value)
    default:
      return state
  }
}

export default outcomeValues
