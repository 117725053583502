export const RESET_STATE = "RESET_STATE"

// SUMMARY OUTCOMES
export const SET_SUMMARY_GROUP = "SET_SUMMARY_GROUP"
export const MOVE_OUTCOME = "MOVE_OUTCOME"
export const GROUP_OUTCOMES = "GROUP_OUTCOMES"

// DECISION
export const SET_DECISION = "SET_DECISION"
export const SET_OTHER_FACTORS_CONTENT = "SET_OTHER_FACTORS_CONTENT"

export const SET_OUTCOME_VALUE = "SET_OUTCOME_VALUE"

export const UPDATE_SELECTED_RISKS = "UPDATE_SELECTED_RISKS"
