import Immutable from "immutable"
import { UPDATE_SELECTED_RISKS } from "../actions/actionTypes"

const initialState = Immutable.fromJS({
  selectedRisks: {
    // name: value
  },
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_SELECTED_RISKS:
      return state.setIn(["selectedRisks", payload.name], payload.value)
    default:
      return state
  }
}
