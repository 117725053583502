import Immutable from "immutable"
import { SET_DECISION, SET_OTHER_FACTORS_CONTENT } from "../actions/actionTypes"

const initialState = Immutable.Map()

const decision = (state = initialState, action) => {
  switch (action.type) {
    case SET_OTHER_FACTORS_CONTENT:
      return state.set("otherFactorsContent", action.content)
    case SET_DECISION:
      return state.set("decision", action.value)
    default:
      return state
  }
}

export default decision
